
import { Options, Vue } from 'vue-class-component';
import { EstimationClient, FabricEstimationClient } from '@/services/Services';
import { ExportServices } from '@/services/ExportServices';
import { baseUrl } from '@/config';
import SummaryCharts from '@/views/calculator/components/summaryCharts.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
        SummaryCharts
    }
})
export default class FabricSummary extends Vue {

    estimationIdentifier: string;
    
    model: OM.SummaryVM = new OM.SummaryVM;
    loaded: boolean = false;

    activeCalculation: number = 0;
    fabricSpecifics: OM.FabricSpecificsVM = new OM.FabricSpecificsVM();

    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();
        this.init();
    }
    
    init() {
        Promise.all([
            EstimationClient.summary(this.estimationIdentifier),
            FabricEstimationClient.getFabricSpecifics(this.estimationIdentifier)
        ])
        .then(xs => {
            this.model = xs[0];
            this.fabricSpecifics = xs[1];

            this.loaded = true;
            store.state.estimationIdentifier = this.estimationIdentifier;
        })
    }

    get getCorrectModel() {
        if(this.activeCalculation == 0)
            return this.model;
        else 
        {
            var totalSQM = this.fabricSpecifics.heightOfRoll * this.fabricSpecifics.lengthOfFabric;
            var totalModel = new OM.SummaryVM();
            totalModel.totalEstimatedUSD = this.model.totalEstimatedUSD * totalSQM;
            totalModel.totalFootprint = this.model.totalFootprint * totalSQM;
    
            this.model.summarySections.forEach(element => {
                var newValue = new OM.SummarySectionVM();
                newValue.footprintLabel = element.footprintLabel;
                newValue.estimatedUSDFootprint = element.estimatedUSDFootprint * totalSQM;
                newValue.footprintKgCOe = element.footprintKgCOe * totalSQM;
                totalModel.summarySections.push(newValue);
            });
    
            return totalModel;
        }
    }

    downloadPDF() {
        EstimationClient.getPDFName(this.estimationIdentifier)
        .then(x => {
            this.$utils.downloadPdf(baseUrl + "api/FabricEstimation/DownloadPDF", this.estimationIdentifier, x);
        })
    }

    exportCsv() {
        EstimationClient.exportCsv(this.estimationIdentifier)
        .then(x => {
            ExportServices.DownloadExport(x.identifier, x.text);
        })
    }

}
