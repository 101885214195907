import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4160fb32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative" }
const _hoisted_2 = { class: "switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_summary_charts = _resolveComponent("summary-charts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["switchButton me-1", _ctx.activeCalculation == 0 ? 'active' : '']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeCalculation = 0))
      }, " 1 SQM Calculation ", 2),
      _createElementVNode("div", {
        class: _normalizeClass(["switchButton", _ctx.activeCalculation == 1 ? 'active' : '']),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeCalculation = 1))
      }, " Total Calculation ", 2)
    ]),
    (_ctx.loaded)
      ? (_openBlock(), _createBlock(_component_summary_charts, {
          key: 0,
          model: _ctx.getCorrectModel,
          onDownloadPDF: _ctx.downloadPDF,
          onExportCsv: _ctx.exportCsv
        }, null, 8, ["model", "onDownloadPDF", "onExportCsv"]))
      : _createCommentVNode("", true)
  ]))
}